.editorWrapper {
  height: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.editorWrapper .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 50px 10px 10px;
}

.editor {
  height: 100%;
  flex-basis: 100%;
}

.toolbar {
  justify-content: space-between;
}

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-height: calc(100vh - 64px);
  height: calc(100vh - 64px);
  overflow-y: hidden;
}

.leftControl {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.rightControl {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.actions .actionButtons a {
  margin-left: 10px;
}
